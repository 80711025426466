:root {
  --primary: #3855F5;
  --secondary: #74788d;
  --soft-secondary: #e1e1e1;
  --success: #34c38f;
  --info: #50a5f1;
  --warning: #f1b44c;
  --danger: #f46a6a;
  --light: #eff2f7;
  --dark: #343a40;
  --white: #ffffff;
  --bs-custom-white: #ffffff;
  --light-pink: #FFF6F6;
  --hover-color: #EFFBFE;
  --main-button-color: #513DCA;
  --main-button-hover-color: #604ec0;
  --selected-menu-item-color: #ECF5F4;
  --main-border-color: #ced4da;
  --header-height: 65px;
  --left-menu-width: 65px;
  --bs-form-check-input-border: #ced4da;
  --bs-body-font-size: 0.7125rem;
  --bs-modal-zindex: 999;
  --bs-modal-header-padding-override: 0.5rem 1rem;
}

html {
  scrollbar-width: none;
}

body {
  background: radial-gradient(circle, rgba(234, 252, 255, 1) 0%, rgba(240, 238, 253, 1) 67%);
}

body.dark {
  color: #fff !important;
  background: #27272B !important;
}

@font-face {
  font-family: 'AvenirNextLTPro';
  src: url('assets/fonts/AvenirNextLTPro-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.page-title-box {
  padding-bottom: 1rem !important;
}

.modal {
  z-index: 999 !important;
}

.modal-backdrop {
  z-index: 998 !important;
}

.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

.modal-header {
  padding: var(--bs-modal-header-padding-override) !important;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

.bg-white {
  background-color: #fff !important;
}

.no-border {
  border: none !important;
}

.fs-tiny {
  font-size: 0.6rem;
}

.primary-button {
  background-color: var(--main-button-color) !important;
  border-color: var(--main-button-color) !important;

  &.small {
    padding: 0.3rem 1rem 0.3rem 1rem;
    font-size: 0.7rem;
  }

  &:hover {
    background-color: var(--main-button-hover-color) !important;
  }
}

.warning-button {
  background-color: #FEE3D4 !important;
  border-color: #FEE3D4 !important;
  padding: 0.2rem 0.4rem;
  height: 27px;
  font-size: 0.7rem;
  color: #C5622A;

  &:hover {
    background-color: #C5622A !important;
    color: #fff;
  }
}

.simplebar-content {
  height: 100%;
}

.secondary-button {
  background-color: var(--selected-menu-item-color) !important;
  border-color: var(--selected-menu-item-color) !important;
  color: var(--main-button-color);
  padding: 0.437rem 0.6rem 0.437rem 0.2rem;

  &.small {
    padding: 0.2rem 0.6rem 0.3rem 0.2rem;
    height: 27px;
    font-size: 0.7rem;

    >i {
      font-size: 0.7rem !important;
    }

    & span {
      font-size: 0.7rem;
      vertical-align: middle;
    }
  }

  &.large {
    padding: 0.5rem 1rem;

    & span {
      font-size: 1rem;
      vertical-align: middle;
    }

    >i {
      vertical-align: middle;
      font-size: 1rem !important;
      margin: auto 8px;
    }
  }

  &:hover {
    background-color: var(--main-button-color) !important;
    color: white;
  }

  &:disabled {
    color: #000000;
  }

  >i {
    vertical-align: text-bottom;
    font-size: 0.8rem !important;
    margin: auto 4px;
  }

  & span {
    font-size: 0.8rem;
    vertical-align: middle;
  }

  &.xsmall {
    padding: 0.1rem 0.4rem 0.3rem 0.1rem;

    >i {
      font-size: 0.75rem !important;
      margin-right: 0.2rem;
      vertical-align: middle;
    }

    & span {
      font-size: 0.7rem;
    }
  }
}

.btn-danger {
  background-color: #E64646 !important;

  &:hover {
    background-color: #e86060 !important;
  }
}

.primary-link {
  color: var(--main-button-color) !important;

  &:hover {
    text-decoration: underline;
  }
}

i.footer-insert-clear-button {
  width: 30px;
  height: 30px;
  background: #F0F1F3;
  color: #64676F;
  padding: 9px 10px;
  border-radius: 3px;
  font-size: 0.8rem;

  &:hover {
    background: #FEF0F0;
    color: #EB5858;
  }
}

i.footer-insert-add-button {
  width: 30px;
  height: 30px;
  padding: 9px 10px;
  border-radius: 3px;
  font-size: 0.8rem;
  background: var(--selected-menu-item-color);
  color: var(--main-button-color);

  &:hover {
    background: var(--main-button-color);
    color: var(--white);
  }
}

i.delete-item-button {
  width: 20px;
  height: 20px;
  background: #F0F1F3;
  color: #64676F;
  padding: 2px 3px;
  border-radius: 3px;
  font-size: 1rem;

  &:hover {
    background: #FEF0F0;
    color: #EB5858;
  }
}

input.form-control {
  display: block;
  width: 100%;
  padding: .37rem .75rem;
  font-size: var(--bs-body-font-size);
  font-weight: 400;
  line-height: 1.5;
  color: #343a40;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid var(--main-border-color);
  -webkit-appearance: none;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &:focus {
    color: #343a40;
    background-color: #fff;
    border-color: rgb(73, 80, 87, .2);
    outline: 0;
    box-shadow: none;
  }

  &.hide-error-image {
    background-image: none !important;
  }
}


select.form-select {
  display: block;
  width: 100%;
  padding: 0.37rem 1.75rem 0.37rem 0.75rem;
  font-size: var(--bs-body-font-size);
  font-weight: 400;
  line-height: 1.5;
  color: #343a40;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid var(--main-border-color);
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;

  &:focus {
    border-color: rgb(73, 80, 87, .2);
    outline: 0;
    box-shadow: 0 0 0 0.15rem #556ee640;
  }
}

textarea.form-control {
  border: 1px solid var(--main-border-color);
  border-radius: .25rem;
  background-color: #FBFBFB !important;

  &:focus {
    border-color: rgb(73, 80, 87, .2);
    outline: 0;
    box-shadow: 0 0 0 0.15rem #556ee640;
  }
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
  border-color: #f46a6a;
  padding-right: calc(1.5em + 0.94rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f46a6a'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f46a6a' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.235rem) center;
  background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

ngx-intl-tel-input>div.iti {
  width: 100%;
}

.country-dropdown {
  font-size: var(--bs-body-font-size) !important;
}

#country-search-box {
  padding: 5px 8px;

  &:focus-visible {
    outline: none !important;
  }
}

.min-table-width {
  min-width: 40rem !important;
}

.table-micro-column {
  width: 3rem;
  min-width: 3rem !important;
}

.table-mini-column {
  width: 8rem;
  min-width: 8rem !important;
}

.table-action-column {
  width: 5rem;
  min-width: 5rem !important;
}

.small-table-column,
.w-10rem {
  width: 10rem;
  min-width: 10rem !important;
}


.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28116, 120, 141, 0.55%29'/%3e%3c/svg%3e") !important;
  background-position: left center;
  border-radius: 2em;
  transition: background-position .15s ease-in-out;
  border: 1px solid var(--main-border-color);
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
}

.btn-close {
  font-size: 1.3rem !important;
}

#list-search-box {
  padding-left: 2rem;
  border-radius: 20px;
}

#list-search-icon {
  font-size: 0.75rem;
  top: 18px;
}

span.list-status-column {
  background-color: #E6F2F1;
  border-radius: 5px;
  padding: 5px 6px;
  font-size: 0.6rem;
}

////=================primng table sort icon
sortalticon,
sortamountupalticon,
sortamountdownicon {
  svg {
    display: none !important;
  }
}

sortalticon:before {
  display: none !important;
}

sortamountupalticon:before {
  content: "\e9a2";
}

sortamountdownicon:before {
  content: "\e9a0";
}

sortalticon:before,
sortamountupalticon:before,
sortamountdownicon:before {
  font-size: 0.8rem;
  font-family: 'primeicons';
}

//============primeng table styling
.p-component {
  font-family: inherit;
  font-size: 0.8rem;
}

.p-inputnumber {
  width: 100%;
}

.p-inputtext:enabled:hover,
.p-inputtext:enabled:focus {
  border-color: var(--main-border-color) !important;
}

.p-datatable .p-sortable-column.p-highlight {
  background: #f8f9fa;
  color: #343a40;
}

.p-datatable .p-datatable-tbody>tr>td {
  padding: 0.4rem;
}

.p-datatable .p-datatable-thead>tr>th {
  padding: 0.7rem 0.4rem;
}

.filter-container {
  width: 200px;
}

.health-exam-table {
  font-family: inherit;
  font-size: 0.8rem;

  &.small {
    td {
      padding: 0.2rem;
    }

    thead tr {
      th {
        padding: 0.2rem;
      }
    }
  }

  tbody {
    tr {
      background: #ffffff;
      color: #495057;
      transition: box-shadow 0.2s;

      td {
        padding: 0.7688rem 0.4rem;
        text-align: left;
        border: 1px solid #dee2e6;
        background-color: #fff;
        border-width: 0 0 1px 0;
      }

      &.insert-row td {
        padding: 0.431rem !important;
      }
    }
  }

  thead tr {
    th {
      text-align: left;
      padding: 0.7rem 0.4rem;
      border: 1px solid #dee2e6;
      border-width: 0 0 1px 0;
      font-weight: 700;
      color: #343a40;
      background: #f8f9fa;
      transition: box-shadow 0.2s;
    }
  }
}

.p-datatable .p-datatable-tbody>tr:hover {
  background: #e9ecef;
}

.p-datatable .p-datatable-header {
  background: #fff;
  color: #343a40;
  border: none;
  padding: 0 0.2rem 1rem 0.2rem;
  font-weight: 700;
}

//=============prime date time control
.p-datepicker .p-timepicker {
  padding: 0.5rem !important;
}

.p-datepicker .p-timepicker button {
  width: 1rem !important;
  height: 1rem !important;
}

.p-datepicker .p-timepicker span {
  font-size: 0.8rem !important;
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 0.2rem !important;
}

.p-link {
  font-size: 0.8rem !important;
}

.p-datepicker table {
  font-size: 0.8rem;
}

.p-datepicker table td {
  padding: 0.1rem;
}

.p-datepicker table td>span {
  width: 2rem;
  height: 2rem;
}

.p-inputtext {
  padding: 0.304rem 0.75rem;
}

.p-inputtext:enabled:focus {
  color: #343a40;
  background-color: #fff;
  border-color: rgba(73, 80, 87, 0.2);
  outline: 0;
  box-shadow: none;
}

.form-check-default,
.form-check-left,
.form-check-right,
.form-check {
  & .form-check-input:checked {
    background-color: var(--main-button-color);
    border-color: var(--main-button-color);
  }
}

.wide-modal-dialog {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
}

.wide-modal-dialog .modal-content {
  width: 100%;
  height: 100%;
}

.modal-fixed-height {
  height: calc(100vh - 10rem);
}

.separator {
  border-left: 2px solid #d2d2d2;
  height: 30px;
  width: 1px;
}

.search-style .ng-arrow-wrapper .ng-arrow {
  display: none !important;
}

.field-section {
  background-color: #FBFBFB;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin-top: 1rem;

  .field-header {
    font-weight: bold;
    font-size: 0.9rem;
    margin-bottom: 0.3rem;
  }

  ul {
    padding: 0.1rem 0 0 1rem;
    margin: 0;
  }
}

.align-left {
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auto-height {
  height: auto !important;
}

.hide-for-desktop {
  display: none !important;
}

@media only screen and (max-width: 767px) {
  .hide-for-mobile {
    display: none;
  }

  .hide-for-desktop {
    display: inline-block !important;
  }

  #app-logo {
    margin-left: 1rem !important;
  }

  .main-content {
    margin-left: 0 !important;
  }

  .page-content {
    padding: 0 !important;
  }

  .p-datatable .p-datatable-tbody>tr>td {
    padding: 0.4rem 0.2rem !important;
    font-size: 0.7rem !important;
  }

  .p-datatable .p-datatable-thead>tr>th {
    padding: 0.7rem 0.2rem !important;
    font-size: 0.7rem !important;
  }

  .table-action-column {
    min-width: 1rem !important;
  }

  .app-header h3.title {
    margin-left: 0.5rem !important;
  }

  .table-mini-column {
    width: 5rem;
    min-width: 5rem !important;
  }

  .table-micro-column {
    width: 1rem;
    min-width: 1rem !important;
  }
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.patient-search-modal>.modal-content {
  min-height: 340px;
}

.app-header {
  popover-container {
    margin: 0 !important;
    left: 124px !important;
    top: -46px !important;
    box-shadow: 0 0 10px .1rem #556ee621;
    min-width: 300px
  }
  
  .popover-body {
    padding: 0 !important;
  }
  .popover-arrow.arrow {
    display: none !important;
  }
}